<template>
    <div>
    <b-modal ref="addNewDptPendukung" title="Tambah Pendukung" hide-footer>
        <div>
            <div class="mb-2 d-none" v-if="$store.getters['account/accountData'].role !== 'caleg'">
                <label for="" class="mb-1">Caleg</label>
                <input type="text" v-model="model.caleg_id">
                <!-- <v-select
                    class="w-100"
                    placeholder="Cari Nama Caleg" v-model="model.caleg_id" :reduce="option => option.value" :options="list.caleg">
                </v-select> -->
            </div>
            <div class="mb-2">
                <label for="" class="mb-1">NIK DPT</label>
                <v-select
                    class="w-100"
                    placeholder="Cari NIK DPT" v-model="model.id" :reduce="option => option.value" :options="list.dpt" @search="doSearch">
                </v-select>
            </div>
            <div v-if="list.dpt.length === 0" class="mt-3">
                <p class="m-0">NIK Tidak Terdaftar? <router-link :to="'/pendukung/create?caleg_id=' + $route.query.caleg_id + '&dapil_id=' + $route.query.dapil_id + '&parliament_region_id=' + $route.query.parliament_region_id" style="text-decoration: underline;">Tambah Pendukung Non-DPT</router-link></p>
            </div>
            <!-- <b-input-group>
                <v-select
                    placeholder="Cari NIK" v-model="model.q" :reduce="option => option.value" @search="doSearch">
                </v-select>
                <b-input-group-append>
                    <b-button variant="outline-primary"><font-awesome-icon icon="search"/><span>Cari</span></b-button>
                </b-input-group-append>
            </b-input-group> -->
            <div class="mt-2" v-if="model.selected">
                <div class="mb-2">
                    <label for="" class="m-0">Kontak No/Hp</label>
                    <b-input type="tel" placeholder="Kontak No. Hp" v-model="model.phone_number"></b-input>
                </div>
                <div v-if="$store.getters['account/accountData'].role !== 'caleg' && model.selected.status_pendukung === 1" class="alert alert-warning mb-2" role="alert">
                    NIK ini telah terdaftar sebagai Pendukung Caleg <strong>{{ model.selected.caleg.map(caleg => {return caleg.name}).join(', ') }}</strong>.
                </div>
                <div>
                    <b-row>
                        <b-col cols="6">
                            <p class="m-0">NKK: <strong>{{model.selected.nkk}}</strong></p>
                        </b-col>
                        <b-col cols="6">
                            <p class="m-0">NIK: <strong>{{model.selected.nik}}</strong></p>
                        </b-col>
                        <b-col cols="6">
                            <p class="m-0">Nama: <strong>{{model.selected.name}}</strong></p>
                        </b-col>
                        <b-col cols="6">
                            <p class="m-0">Kab: <strong>{{model.selected.city.name}}</strong></p>
                        </b-col>
                        <b-col cols="6">
                            <p class="m-0">Kec: <strong>{{model.selected.district.name}}</strong></p>
                        </b-col>
                        <b-col cols="6">
                            <p class="m-0">Kel: <strong>{{model.selected.village.name}}</strong></p>
                        </b-col>
                        <b-col cols="12">
                            <p class="m-0">Alamat: <strong>{{model.selected.address}}</strong></p>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <b-button variant="primary" type="button" :disabled="isLoading" @click.prevent="submitDptPendukung">Tambah</b-button>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>
<script>
import Dpt from '@/models/Dpt.js'
import User from '@/models/User.js'

const dptModel = new Dpt()
const calegModel = new User()

export default {
  data () {
    return {
        isLoading: false,
        model: {
            id: null,
            caleg_id: this.$route.query.caleg_id,
            phone_number: null,
            selected: null,
        },
        list: {
            caleg: [],
            dpt: [],
        }
    }
  },
  mounted () {
    // if (this.$store.getters['account/accountData'].role !== 'caleg') {
    //     this.getCaleg()
    // }
  },
  watch: {
    'model.id': function (newValue) {
        this.model.selected = null
        if (newValue) {
            this.model.selected = this.list.dpt[0].detail
        }
    },
  },
  methods: {
    show () {
        this.$refs.addNewDptPendukung.show()
    },
    hide () {
        this.$refs.addNewDptPendukung.hide()
    },
    async getCaleg () {
        this.isLoading = true;
        try {
            const calegList = await calegModel.list ({
                only_has_access: 1,
                with_user_work_area: 1,
                limit: 100,
                roles: 'caleg'
            });
            const options = []
            calegList.data.forEach(user => {
                options.push({
                    value: user.id,
                    detail: user,
                    label: user.name + ', Partai: ' + user.user_work_area.party.name
                });
            });
            this.list.caleg = options
        } catch (e) {
            console.error(e)
            this.$store.dispatch('notification/error', e)
        }
        this.isLoading = false;
    },
    async submitDptPendukung () {
        this.isLoading = true;
        try {
            await dptModel.attachCaleg (this.model.id, this.$store.getters['account/accountData'].role !== 'caleg' ? this.model.caleg_id : this.$store.getters['account/accountData'].id, {
                phone_number: this.model.phone_number
            });
            this.$store.dispatch('notification/success', 'Berhasil menambahkan DPT sebagai pendukung')
            this.$emit('submitted', this.model.selected)
            this.hide();
            this.model.id = null;
            this.model.caleg_id = null;
            this.model.phone_number = null;
            this.model.selected = null;
        } catch (e) {
            console.error(e)
            this.$store.dispatch('notification/error', e)
        }
        this.isLoading = false;
    },
    async doSearch (search, loading) {
        loading(true)
        if (search) {
            const userList = await dptModel.checkNIK(search);
            const options = []
            userList.forEach(user => {
                options.push({
                    value: user.id,
                    detail: user,
                    label: user.name + ', NIK: ' + user.nik
                });
            });
            this.list.dpt = options
        }
        loading(false)
    }
  },
}
</script>